import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";
import Layout from "../components/layout";
import {FullWidthTabs,} from "../components/mortgageCalc";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: "1rem",
        [theme.breakpoints.down("sm")]: {
            padding: "0",
        },
    },
    paragraph: {
        margin: "1rem auto",
        [theme.breakpoints.up("lg")]: {
            maxWidth: "70vw",
        },
    },
    imageContainer: {
        textAlign: "center",
    },
    image: {
        width: "80%",
        margin: "auto",
    },
    button: {
        margin: "0 1rem 1rem 0",
        fontWeight: "bold",
    },
}));

export default function Buy() {
    const classes = useStyles();
    return (
        <Layout>
            <Grid container alignItems="flex-start" className={classes.container}>
                <Grid
                    container
                    item
                    sm={12}
                    md={6}
                    className={classes.imageContainer}
                >
                    <img
                        src="/estimate_house.svg"
                        alt=""
                        className={classes.image}
                    />
                </Grid>
                <Grid container item sm={12} md={6}>
                    <Grid item className={classes.paragraph}>
                        <Typography variant="h3">Financial Planning</Typography>
                    </Grid>
                    <Grid item className={classes.paragraph}>
                        <FullWidthTabs></FullWidthTabs>
                    </Grid>
                </Grid>
            </Grid>
        </Layout>
    );
}
