import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    mortgage: { 
        width: "100%", 
        height: "640px" ,
        [theme.breakpoints.down("sm")]: {
            height: "800px" 
        }
    },
    land: { 
        width: "100%", 
        height: "300px" ,
        [theme.breakpoints.down("sm")]: {
            height: "450px" 
        }
    },
    insurance: { 
        width: "100%", 
        height: "300px" ,
        [theme.breakpoints.down("sm")]: {
            height: "300px" 
        }
    },

}));

export function MortgagePaymentCalc() {
    const classes = useStyles();
    return (
        <iframe
            title="MortgagePaymentCalc"
            allowtransparency="true"
            frameBorder="0"
            scrolling="no"
            src="https://www.ratehub.ca/widgets/payment-calc.php?lang=en&amp;ac=0500"
            className={classes.mortgage}
        ></iframe>
    );
}

export function MortgageAffordabilityCalc() {
    const classes = useStyles();
    return (
        <iframe
            title="MortgageAffordabilityCalc"
            allowtransparency="true"
            frameBorder="0"
            scrolling="no"
            src="https://www.ratehub.ca/widgets/affordability-calc.php?lang=en&amp;ac=0501"
            className={classes.land}
        ></iframe>
    );
}

export function LandTransferTaxCalc() {
    const classes = useStyles();
    return (
        <iframe
            title="LandTransferTaxCalc"
            allowtransparency="true"
            frameBorder="0"
            scrolling="no"
            src="https://www.ratehub.ca/widgets/payment-calc.php?ltt=only&amp;lang=en&amp;ac=0502"
            className={classes.land}
        ></iframe>
    );
}

export function CMHCInsuranceCalc() {
    const classes = useStyles();
    return (
        <iframe
            title="CMHCInsuranceCalc"
            allowtransparency="true"
            frameBorder="0"
            scrolling="no"
            src="https://www.ratehub.ca/widgets/payment-calc.php?cmhc=only&amp;lang=en&amp;ac=0503"
            className={classes.insurance}
        ></iframe>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}


export function FullWidthTabs() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div className={classes.root}>
            <AppBar elevation={0} position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Mortgage" {...a11yProps(0)} />
                    <Tab label="Insurance" {...a11yProps(1)} />
                    <Tab label="Tax" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel
                    id="mortgage"
                    style={{ overflowX: "auto" }}
                    value={value}
                    index={0}
                    dir={theme.direction}
                >
                    <Typography variant="h4">
                        Mortgage Payment Calculator
                    </Typography>
                    <Typography>
                        Test different payment scenarios depending on your
                        amortization period, payment frequency or the mortgage
                        amount.
                    </Typography>
                    <MortgagePaymentCalc />
                </TabPanel>
                <TabPanel
                    id="insurance"
                    style={{ overflowX: "auto" }}
                    value={value}
                    index={1}
                    dir={theme.direction}
                >
                    <Typography variant="h4">
                        CMHC Insurance Calculator
                    </Typography>
                    <Typography>
                        Mortgage Loan Insurance from Canada Mortgage and Housing
                        Corporation (CMHC) can help bring homeownership within
                        reach. Mortgage loan insurance is typically required by
                        lenders when homebuyers make a down payment of less than
                        20% of the purchase price.
                    </Typography>
                    <CMHCInsuranceCalc />
                </TabPanel>
                <TabPanel
                    id="tax"
                    style={{ overflowX: "auto" }}
                    value={value}
                    index={2}
                    dir={theme.direction}
                >
                    <Typography variant="h4">
                        Land Transfer Tax Calculator
                    </Typography>
                    <Typography>
                        Land transfer tax (LTT) is often overlooked when
                        considering the total cost of purchasing a home. In most
                        provinces the tax is calculated as a percentage of
                        property value, using asking price as a close estimate.
                        Homebuyers in Toronto, however, also incur an additional
                        municipal tax.
                    </Typography>
                    <LandTransferTaxCalc />
                </TabPanel>
            </SwipeableViews>
        </div>
    );
}
